import React from 'react';
import { Helmet } from 'react-helmet';
import blogs from '../Data/blogs';
import FadeImage from '../Components/FadeImage';
import Image from '../Components/Image';
import Tag from '../Components/Tag';
import Share from '../Components/Share';
import Carousel from '../Components/Carousel';

class BlogPost extends React.Component {
    constructor(props) {
        super(props);

        this.state = { carousel: null };
    }

    generateBlogBody = body => {
        var generatedBody = [];
        var key = 0;

        body.forEach(element => {
            var className = element.class || 'col-xs-12';
            switch (element.type) {
                case 'p':
                    generatedBody.push(
                        <p key={key++} className={className}>
                            {element.value}
                        </p>
                    );
                    break;
                case 'pBold':
                    generatedBody.push(
                        <p key={key++} className={className}>
                            <b>{element.value}</b>
                        </p>
                    );
                    break;
                case 'pHeading':
                    generatedBody.push(
                        <p key={key++} className={className}>
                            <b>{element.value.heading}</b>
                            <br />
                            {element.value.body}
                        </p>
                    );
                    break;
                case 'list':
                    generatedBody.push(
                        <ul key={key++} className={className}>
                            {element.value.map(val => (
                                <li key={key++}>{val}</li>
                            ))}
                        </ul>
                    );
                    break;
                case 'img':
                    generatedBody.push(<Image key={key++} singleSrc={element.value} className={className} />);
                    break;
                case 'carousel':
                    generatedBody.push(
                        <Carousel key={key++} images={element.value} style={{ height: '400px' }}>
                            {this.state.carousel}
                        </Carousel>
                    );
                    break;
                default:
                    break;
            }
        });

        return generatedBody;
    };

    generateMetaTags(blog) {
        var tags = [];

        tags.push(<meta key="meta_1" property="og:title" content={blog.title} />);
        tags.push(<meta key="meta_2" property="og:description" content={blog.description} />);
        tags.push(<meta key="meta_3" property="og:image" content={blog.hero} />);
        tags.push(<meta key="meta_4" property="og:url" content={'https://www.orteagone.com/Blog/' + blog.type + '/' + blog.seo + '/'} />);

        tags.push(<meta key="meta_5" name="twitter:title" content={blog.title} />);
        tags.push(<meta key="meta_6" name="twitter:description" content={blog.description} />);
        tags.push(<meta key="meta_7" name="twitter:image" content={blog.hero} />);
        tags.push(<meta key="meta_8" name="twitter:card" content="summary_large_image" />);

        return tags;
    }

    render() {
        var seoName = this.props.match.params.blogSeoName || '';
        var blog = blogs.find(b => b.seo === seoName);

        return (
            <main>
                <Helmet>
                    <title>Orteagone - {blog.title}</title>
                    <link rel="canonical" href="http://mysite.com/example" />
                    {this.generateMetaTags(blog)}
                </Helmet>
                <div className="wrapper">
                    <FadeImage singleSrc={blog.hero} className="hero" />
                    <h1>{blog.title}</h1>
                    <section>
                        <div className="row">{this.generateBlogBody(blog.body)}</div>
                        <div className="blogBottom">
                            <div className="info">
                                <Share title={blog.title} seo={blog.seo} description={blog.description} />
                                <br />
                                <div className="date">{blog.dateAdded}</div>
                                <aside className="tags">
                                    {blog.tags.map(tag => (
                                        <Tag key={blog.seo + tag} tagName={tag} />
                                    ))}
                                </aside>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        );
    }
}
export default BlogPost;
