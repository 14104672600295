import React from "react";
import { Link } from "react-router-dom";
import FadeImage from "../Components/FadeImage";

function Home() {
  return (
    <main className="home">
      <div className="home row">
        <Link to="/Blog/Beauty/" className="col-xs-12 col-sm-6 col-md-4">
          <FadeImage
            singleSrc="https://api.orteagone.com/Images/Beauty.jpg"
            alt="Beauty"
          />
          <span className="title">Beauty</span>
        </Link>
        <Link to="/Blog/Crafts/" className="col-xs-12 col-sm-6 col-md-4">
          <FadeImage
            singleSrc="https://api.orteagone.com/Images/Crafts.jpg"
            alt="Crafts"
          />
          <span className="title">Crafts</span>
        </Link>
        <Link to="/Blog/Collections/" className="col-xs-12 col-sm-6 col-md-4">
          <FadeImage
            singleSrc="https://api.orteagone.com/Images/Collections.jpg"
            alt="Collections"
          />
          <span className="title">Collections</span>
        </Link>
        <Link to="/Blog/K-DASH/" className="col-xs-12 col-sm-6 col-md-4">
          <FadeImage
            singleSrc="https://api.orteagone.com/Images/K-DASH.jpg"
            alt="K-DASH"
          />
          <span className="title">K-DASH</span>
        </Link>
        <Link to="/Blog/Travel/" className="col-xs-12 col-sm-6 col-md-4">
          <FadeImage
            singleSrc="https://api.orteagone.com/Images/Travel.jpg"
            alt="Travel"
          />
          <span className="title">Travel</span>
        </Link>
        <Link to="/Blog/Personal/" className="col-xs-12 col-sm-6 col-md-4">
          <FadeImage
            singleSrc="https://api.orteagone.com/Images/Personal.jpg"
            alt="Personal"
          />
          <span className="title">Personal</span>
        </Link>
      </div>
    </main>
  );
}

export default Home;
