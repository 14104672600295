import React from 'react';

class Share extends React.Component {
    render() {
        var { title, description } = this.props;

        var url = window.location.href;
        var encodedUrl = encodeURIComponent(url);
        var encodedTitle = encodeURIComponent(title);
        var encodedDescription = encodeURIComponent(description);

        return (
            <React.Fragment>
                <ul className="share-buttons">
                    <li>
                        <a href={'https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl} rel="noopener noreferrer" target="_blank" title="Share on Facebook">
                            <i className="fab fa-facebook-square fa-2x" aria-hidden="true" />
                            <span className="sr-only">Share on Facebook</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href={'https://twitter.com/intent/tweet?source=' + encodedUrl + '&text=' + encodedTitle + ':%20' + encodedUrl + '&via=orteagone'}
                            rel="noopener noreferrer"
                            target="_blank"
                            title="Tweet"
                        >
                            <i className="fab fa-twitter-square fa-2x" aria-hidden="true" />
                            <span className="sr-only">Tweet</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href={'mailto:?subject=' + encodedTitle + '&body=' + encodedDescription + ':%20' + encodedUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                            title="Send email"
                        >
                            <i className="fas fa-envelope-square fa-2x" aria-hidden="true" />
                            <span className="sr-only">Send email</span>
                        </a>
                    </li>
                </ul>
                {/*<Link className="tag" to={"/Tag/" + to}>
          {tagName}
    </Link>*/}
            </React.Fragment>
        );
    }
}

export default Share;
