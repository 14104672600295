import React from "react";
import Image from "./Image";

class FadeImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { faded: false };
  }

  componentDidMount() {
    this.setState({ faded: false });
  }

  splice = (string, start, delCount, newSubStr) => {
    return (
      string.slice(0, start) +
      newSubStr +
      string.slice(start + Math.abs(delCount))
    );
  };

  render() {
    var { singleSrc, alt, style, className } = this.props;

    //TODO: Make a console log here to make sure all 4 versions are available

    var webp = singleSrc.substring(0, singleSrc.lastIndexOf(".")) + ".webp";

    var blurrySrc = this.splice(
      singleSrc,
      singleSrc.lastIndexOf("."),
      0,
      "_blurred"
    );
    var blurryWebp = this.splice(webp, webp.lastIndexOf("."), 0, "_blurred");

    return (
      <div style={style} className={className}>
        {this.state.faded ? (
          <Image webp={blurryWebp} src={blurrySrc} alt={alt} />
        ) : (
          <Image webp={webp} src={singleSrc} alt={alt} />
        )}
      </div>
    );
  }
}
export default FadeImage;
