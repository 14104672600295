import React from "react";
import { Link } from "react-router-dom";

class Tag extends React.Component {
  render() {
    var { tagName } = this.props;

    var to = tagName; //TODO:Need to remove spaces here

    return (
      <Link className="tag" to={"/Tag/" + to}>
        {tagName}
      </Link>
    );
  }
}

export default Tag;
