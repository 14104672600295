import React from "react";
import { Link } from "react-router-dom";
import {} from "flexboxgrid";
import Image from "./Image";

function Header() {
  return (
    <header>
      <nav>
        <div className="row">
          <div className="col-xs-4 col-sm-2">
            <Link to="/Blog/Beauty/">Beauty</Link>
          </div>
          <div className="col-xs-4 col-sm-2">
            <Link to="/Blog/Crafts/">Crafts</Link>
          </div>
          <div className="col-xs-4 col-sm-2">
            <Link to="/Blog/Collections/">Collections</Link>
          </div>
          <div className="col-xs-4 col-sm-2">
            <Link to="/Blog/K-DASH/">K-DASH</Link>
          </div>
          <div className="col-xs-4 col-sm-2">
            <Link to="/Blog/Travel/">Travel</Link>
          </div>
          <div className="col-xs-4 col-sm-2">
            <Link to="/Blog/Personal">Personal</Link>
          </div>
        </div>
      </nav>
      <Link className="logoImg" to="/">
        <Image
          disableExpand
          webp={require("../Images/Logo.webp")}
          src={require("../Images/Logo.jpg")}
        />
      </Link>
    </header>
  );
}

export default Header;
