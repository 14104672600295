import React from "react";
import blogs from "../Data/blogs";
import { Link } from "react-router-dom";

function Sitemap() {
  var types = blogs.map(blog => blog.type);
  types = types.filter((v, i, a) => a.indexOf(v) === i);

  var tags = blogs.map(blog => blog.tags);
  tags = [].concat.apply([], tags);
  tags = tags.filter((v, i, a) => a.indexOf(v) === i);

  return (
    <main className="map">
      <div className="wrapper">
        <h2>Blog Categories</h2>
        {types.map(type => (
          <Link key={type} to={"/Blog/" + type + "/"}>
            {type}
          </Link>
        ))}
        <h2>Blog Entries</h2>
        {blogs.map(blog => (
          <Link key={blog.seo} to={"/Blog/" + blog.type + "/" + blog.seo + "/"}>
            {blog.title}
          </Link>
        ))}
        <h2>Blog Tags</h2>
        {tags.map(tag => (
          <Link key={"tag" + tag} to={"/Tag/" + tag + "/"}>
            {tag}
          </Link>
        ))}
      </div>
    </main>
  );
}

export default Sitemap;
