import React from "react";
import { render } from "react-snapshot";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import BlogType from "./Pages/BlogType";
import BlogPost from "./Pages/BlogPost";
import Tag from "./Pages/Tag";
import Sitemap from "./Pages/Sitemap";
import NotFound from "./Pages/NotFound";
import * as serviceWorker from "./serviceWorker";
import "./Css/default.css";

const routing = (
  <Router>
    <Header />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/Blog/:blogType/" component={BlogType} />
      <Route path="/Blog/:blogType/:blogSeoName/" component={BlogPost} />
      <Route exact path="/Tag/:tag/" component={Tag} />
      <Route exact path="/Sitemap/" component={Sitemap} />
      <Route component={NotFound} />
    </Switch>
    <Footer />
  </Router>
);

render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
