const blogs = [
    {
        seo: 'How-To-Pack-For-A-Trip',
        title: 'How To Pack for a Trip',
        type: 'Travel',
        tags: ['Packing', 'Organizing', 'Vacation'],
        dateAdded: '2016-02-20',
        dateUpdated: '2019-05-08',
        hero: 'https://api.orteagone.com/Images/Blog/Packing.jpg',
        description: 'This is my way of packing efficently and organized. Tools I needed for this were; Ziplock bags, yarn, sharpies, and sticky notes.',
        body: [
            {
                type: 'p',
                value: 'This is my way of packing efficently and organized. Tools I needed for this were; Ziplock bags, yarn, sharpies, and sticky notes.'
            },
            {
                type: 'list',
                value: [
                    'First: To be completely organized I had to plan out each outfit for each day we will be there. I made sure to grab a pair of underwear and socks for each day as well.',
                    'Second: I labeled my days, using the sticky notes and sharpies. For instance Day One "Epcot" = Guardians of the Galaxy shirts + Underwear & Socks. I then placed the shirt out on the floor, placed the underwear and socks on top and rolled them into circles.',
                    'Third: I used yarn to keep the outfit tightly in place and placed the sticky note under it. I also color cordinated the yarn and sticky notes so that one color was my husbands (orange) and one was mine (green).',
                    'Fourth: Placed the rolls of outfits into the Ziplock bags. I was able to get three days of outfits into my bag and two days into my husbands.'
                ]
            },
            {
                type: 'p',
                value:
                    "After I did all eleven days, I packed our sleepwear and swimswear in the same style. We were able to fit all of this in our duffle and small suitcase. We ended up packing a suitcase with a suitcase because we know we will come back with a lot of souveniers. Packing this way helped us meet our weight requirement on our flights and have the room we wanted for our travel back home. I'm currently on day two of our vacation and it has been really easy unpacking our outfits. We even have been putting our dirty clothes back in the ziplock bags. That way we dont smell up the suitcases and other clean things. Lists and color organization make my heart happy. I hope these tips for packing have helped you guys for your trips."
            },
            { type: 'p', value: '-Sarah' }
        ]
    },
    {
        seo: 'The-Weessies-Family-is-Upgrading-to-SGWx3',
        title: 'The Weessies Family is Upgrading to SGWx3',
        type: 'Personal',
        tags: ['Life', 'Family', 'News', 'Baby'],
        dateAdded: '2019-05-12',
        dateUpdated: '2019-06-29',
        hero: 'https://api.orteagone.com/Images/Blog/Preg_11.jpg',
        description: "I would like to share with you all our journey to starting a family. It's kind of long so bear with me.",
        body: [
            {
                type: 'p',
                value:
                    "I would like to share with you all our journey to starting a family. It's kind of long so bear with me. We never really talked about how we were struggling with conceiving a child. It was something personal and honestly hard to talk about. Especially when I was constantly asked “How come you don't have kids yet? Don't you guys want to have kids?”. "
            },
            {
                type: 'p',
                value:
                    "Truth is we started trying to have a baby in the Fall of 2016. It seemed like for us, it was just not as easy as everyone said it was. As fall of 2017 approached we didn't have any good news to share.. I had some late periods which gave us a glimmer of hope, but ultimately nothing happened. "
            },
            {
                type: 'p',
                value:
                    "I call 2018 the year of grief. I lost both my grandpa's in a five week span and Stephen lost his grandfather in November. We experienced so much loss and dealt with depression. Losing three loved ones within the year, a job change, and then ultimately the decision to move. It was sad. I felt like my body was too stressed and depressed to continue trying so we took a break."
            },
            {
                type: 'p',
                class: 'col-xs-12 col-sm-6 col-md-9',
                value:
                    'As the beginning of 2019 came we started it off with change. I quit my job, packed up our house and we moved up to Gilroy. We put our house on the market and it sold very quickly. All of a sudden everything started to feel "calm and peaceful". With the house selling Stephen paid off my student loans; which took a great burden off of my shoulders. We got Stephen\'s new car; a Tesla model 3. Stephen was blessed and found another amazing job at Driscolls and I\'ve been acclimating to being a stay at home wifey. '
            },
            {
                type: 'img',
                class: 'col-xs-12 col-sm-6 col-md-3',
                value: 'https://api.orteagone.com/Images/Blog/Preg_02.jpg'
            },
            {
                type: 'img',
                class: 'col-xs-12 col-sm-6 col-md-3',
                value: 'https://api.orteagone.com/Images/Blog/Preg_03.jpg'
            },
            {
                type: 'p',
                class: 'col-xs-12 col-sm-6 col-md-9',
                value:
                    "This is when our miracle happened. I noticed my period was late in March and decided to take a pregnancy test. For the first time in two and a half years I had a second line! It was faint but it was there. I freaked out and took three more tests. All said positive!  I was four weeks pregnant! That night I told Stephen and we both cried tears of joy. The next week we told my parents, his parents, sister in law and brother in law. It was just something we couldn't keep from them; especially since most of us live under the same roof."
            },
            {
                type: 'p',
                class: 'col-xs-12 col-sm-6 col-md-9',
                value:
                    'I had a bleeding scare during week six of my pregnancy and had to go in early and have an ultrasound done. However with that ultrasound, we saw a healthy baby with a healthy heartbeat. '
            },
            {
                type: 'img',
                class: 'col-xs-12 col-sm-6 col-md-3',
                value: 'https://api.orteagone.com/Images/Blog/Preg_05.jpg'
            },
            {
                type: 'img',
                class: 'col-xs-12 col-sm-6 col-md-3',
                value: 'https://api.orteagone.com/Images/Blog/Preg_04.jpg'
            },
            {
                type: 'p',
                class: 'col-xs-12 col-sm-6 col-md-9',
                value:
                    "We are expecting baby SGW in December of 2019. We are both overjoyed and beyond blessed that this is finally happening to us. I just feel so loved and blessed. Words can't express how happy we are. "
            },
            {
                type: 'p',
                value: 'We love you all and thank you for listening to our  story. '
            },
            {
                type: 'p',
                value: 'Mark 11:24 (NIV) “Therefore I tell you, whatever you ask for in prayer, believe that you have received it, and it will be yours.”'
            },
            { type: 'p', value: '-Sarah and Stephen Weessies ' }
        ]
    },
    {
        seo: 'Second-Trimester-Here-We-Go',
        title: 'Second Trimester Here We Go!',
        type: 'Personal',
        tags: ['Life', 'Family', 'Update', 'Baby'],
        dateAdded: '2019-06-18',
        dateUpdated: '2019-06-29',
        hero: 'https://api.orteagone.com/Images/Blog/Ultrasound_13Weeks_Collage1.jpg',
        description:
            "We are in our Second Trimester now. We're are about four weeks away from when we find out the gender of our baby. We are beyond excited and can't wait to find out what the baby is.",
        body: [
            {
                type: 'p',
                value:
                    "We are in our Second Trimester now. We're are about four weeks away from when we find out the gender of our baby. We are beyond excited and can't wait to find out what the baby is."
            },
            {
                type: 'p',
                value:
                    "About four weeks ago I was diagnosed with gestational diabetes and have been in a nutrition program to help me with GDM. At first it was mentally daunting on me because I felt bad that my body and placenta were not cooperating together. It was really hard on me because I felt like I did something wrong. My nutritionist explained that women in all shapes and sizes can get this and we did nothing wrong. That was when I changed my mindset from being depressed about it to let's change my lifestyle so this can be an easier pregnancy. With the support of my family, doctor's and friends I've been striving in my program. I've lost around 12 pounds and I feel so much healthier. I'm on a nice healthy meal plan and exercise regimen that is both great for myself and for my little one."
            },
            {
                type: 'p',
                value:
                    "I don't quite have a bump that's visible yet but it is growing and I feel the changes happening in my body. My cats are very needy and want to be near my bump area all the time. It's actually really cute. I need naps every now and then and try to take advantage of them while I can pre-baby."
            },
            {
                type: 'p',
                value:
                    "The doctors are very happy with how Baby SGW is growing. We love ultrasounds and OB GYN visits because we get to hear the heartbeat and see our baby. Stephen and I are just overjoyed with happiness. We're just counting down the days till July 18th and we find out the gender. We will let everyone know on July 21st what the gender is."
            },
            {
                type: 'p',
                value: 'Thank you all for following our Pregnancy Journey.'
            },
            {
                type: 'p',
                value: 'Love, Sarah and Stephen.'
            },
            {
                type: 'img',
                value: 'https://api.orteagone.com/Images/Blog/Ultrasound_13Weeks_Collage2.jpg'
            }
        ]
    },
    {
        seo: 'The-Big-Reveal',
        title: 'The Big Reveal',
        type: 'Personal',
        tags: ['Life', 'Family', 'Update', 'Baby'],
        dateAdded: '2019-07-21',
        dateUpdated: '2019-07-21',
        hero: 'https://api.orteagone.com/Images/Blog/baby_gender_voting.jpg',
        description:
            'The last three days have been days filled with joy and excitement! We went to our 20 week anatomy ultrasound ready to find out the gender of our baby and to see how the baby was progressing. With my gestational diabetes and some family genetics we were concerned that there could potentially be some issues. Thankfully our baby is very healthy.',
        body: [
            {
                type: 'p',
                value:
                    "The last three days have been days filled with joy and excitement! We went to our 20 week anatomy ultrasound ready to find out the gender of our baby and to see how the baby was progressing. With my gestational diabetes and some family genetics we were concerned that there could potentially be some issues. Thankfully our baby is very healthy. It's even in the 96th percentile in shoulders (2 weeks ahead of gestation).  Most of the baby was marking at least one week ahead of gestation. Which we figured would be the case since Stephen is very tall and was a long baby himself. Since I was premature it's hard to gauge how long or big I would've been full term."
            },
            {
                type: 'p',
                value:
                    'It was such a relief to hear that all the parts are growing properly and they didn’t see any complications. The doctor just kept smiling and saying she has nothing to report because everything looks on great.'
            },
            {
                type: 'p',
                value:
                    'Now to the Gender reveal. We told them we wanted to know and that it is not a secret. We told the receptionist “Please tell us! We are dying! We need to know!”. When the sonographer was taking the photos she blurted out a gender body part and looked over at us with a scared look and said “You guys already knew you were having a girl right?” Steve and I look at each other and both say “No we didn’t know! But we wanted to know! OMG we’re having a girl?” She then zoomed back out and showed us our baby’s gender and wrote “It’s a Girl” on the photos.'
            },
            {
                type: 'p',
                value:
                    'Both of our hearts were bursting with happiness and love. We get asked a lot if we were hoping for a specific gender. Honestly we both just want a healthy baby and we truly didn’t care of the gender. When you wait for two and a half years for a baby your heart is happy with whatever comes your way. We will say this though, the thought of having a girl would be the first granddaughter on the Weessies side. On the Ortegon side I’m an only child, so this baby is my parents first grandchild. We both had thought that if we did have a girl she probably would be spoiled. Grandpa’s girl all the way!'
            },
            {
                type: 'p',
                value: 'With that being said we are having a beautiful BABY GIRL! We are naming her;'
            },
            {
                type: 'pBold',
                value: 'Sophia Grace Weessies'
            },
            {
                type: 'img',
                value: 'https://api.orteagone.com/Images/Blog/cake.jpg'
            },
            {
                type: 'p',
                value:
                    'We are keeping on the tradition with the initials of SGW. We chose “Sophia” because it is spelled with a “ph” like her Dad, Stephen. We chose “Grace” because its my middle name and its my mother in law Melanie’s middle name.'
            },
            {
                type: 'p',
                value: 'Thank you all for your prayers and well wishes.'
            },
            {
                type: 'p',
                value: ' - Love, Sarah, Stephen, and Sophia.'
            }
        ]
    },
    {
        seo: 'Babymoon-Trip-to-Las-Vegas',
        title: 'Babymoon: Trip to Las Vegas',
        type: 'Travel',
        tags: ['Life', 'Family', 'Travel', 'Update', 'Baby'],
        dateAdded: '2019-10-07',
        dateUpdated: '2019-10-07',
        hero: 'https://api.orteagone.com/Images/Blog/Babymoon_arrival_1.jpg',
        description:
            'Viva Las Vegas! When we found out we were expecting we immediately talked about planning a Babymoon. We thought of Hawaii, Disneyland and Las Vegas. Ultimately we landed on Vegas because it was a very short flight from the Bay Area.',
        body: [
            {
                type: 'p',
                value: 'Viva Las Vegas! '
            },
            {
                type: 'p',
                value:
                    'When we found out we were expecting we immediately talked about planning a Babymoon. We thought of Hawaii, Disneyland and Las Vegas. Ultimately we landed on Vegas because it was a very short flight from the Bay Area. We wanted to go right before I hit my third trimester which we assumed I would be bigger and more tired in those months. We thought that seeing shows and walking in the malls would be a lot of fun for us both.'
            },
            {
                type: 'p',
                value:
                    "We ended up booking everything back in May and planned on our baby moon being our 26th-27th week of Pregnancy. Which meant an  August 31st-September 7th vacation. August was here and we were approaching the big week then that's when we got the heartbreaking news about my Grandma Ruth. On August 22nd my sweet beautiful Grandmother passed away and it shook me to the core. I knew her time was near; but no matter how much you “try” to prepare yourself for it, it's just not easy. Especially pregnant with extra hormones. I had high anxiety which I tried not to show because everyone was worried about me."
            },
            {
                type: 'p',
                value:
                    'We laid my grandmother in her final resting place right next to my grandpa (who we lost last year). Stephen recommended that we still plan on going on our Baby Moon because he thought the distraction might help me deal with my grief a little better. I’ll be honest I was so drained from my emotions a vacation seemed like the last thing I wanted to do.  However I sure am glad I listened to my husband and went through with our plans. It was honestly just what I needed!'
            },
            {
                type: 'pBold',
                value: 'Here are small recaps of our time in Las Vegas for our Baby Moon.'
            },
            {
                type: 'pHeading',
                value: {
                    heading: 'Arrival Day:',
                    body:
                        "We arrived in Vegas Saturday evening and had dinner and checked into our hotel which was beautiful! We stayed at the Palazzo. I know you all think I’m bougie but we are what I call “Bougie on a Budget”. We used Priceline and got an amazing deal! If you ever need travel tips just ask Stephen. He is “Mr. Find the Deals Weessies”. He loves planning and especially planning for trips. He always manages to get us deals, coupons, you name it. It's how we are able to experience nicer things while not breaking the bank. "
                }
            },
            {
                type: 'carousel',
                value: ['https://api.orteagone.com/Images/Blog/Babymoon_arrival_2.jpg']
            },
            {
                type: 'pHeading',
                value: {
                    heading: 'Day 1:',
                    body:
                        "On Sunday we explored the outlets and partook in some fabulous deals. We had a fun dinner at “Hash Hash A Go Go” which was yummy but they serve you way too much. I don’t think it's possible to finish your plate of food there. Then we went and saw “O” By Cirque du soleil. It was the 10,000th show and they were phenomenal. I’ve never seen a show like that before."
                }
            },
            {
                type: 'carousel',
                value: ['https://api.orteagone.com/Images/Blog/Babymoon_day_1_1.jpg']
            },
            {
                type: 'pHeading',
                value: {
                    heading: 'Day 2:',
                    body:
                        'My parents arrived in Vegas on Monday and we had the Jabbawokeez planned for that night. Seeing my dads face light up because of how awesome they are live was awesome! Priceless. Jabbawokeez never disappoint! They can entertain a crowd.'
                }
            },
            {
                type: 'carousel',
                value: ['https://api.orteagone.com/Images/Blog/Babymoon_day_2_1.jpg', 'https://api.orteagone.com/Images/Blog/Babymoon_day_2_2.jpg']
            },
            {
                type: 'pHeading',
                value: {
                    heading: 'Day 3:',
                    body:
                        'The big event day was Tuesday: Bruno Mars for my mom and I. Le Rev for my dad and Stephen. We had dinner at Bobby Flay’s Mesa Grill. Which was fantastic! Best steamed halibut I’ve ever had. Then we split up and went our respected directions down the strip to see our shows. My mom and I had a very long,tiring, hot walk in the heat (which I’ll never do again) and then finally made it to the concert. Thank the lord for air conditioning! The Park MGM Theater is a fantastic venue and Bruno Mars knows how to perform. That was one of, if the not best concert I’ve ever been to in my life! He is fabulous and I highly recommend if you have a chance to see him, do it! Bruno Mars was a phone free night because he paid to have our phones sealed in “Yondr” pouches. He wanted us to disconnect from our phones, the world, the sadness going on in our lives. He wanted us to be in the moment and have a fun sexy time. I’m honestly glad he did that. No one was worried about recording anything or taking photos. We just simply danced, sang, and experience the music. It was beautiful and fun!'
                }
            },
            {
                type: 'carousel',
                value: ['https://api.orteagone.com/Images/Blog/Babymoon_day_3_1.jpg', 'https://api.orteagone.com/Images/Blog/Babymoon_day_3_2.jpg']
            },
            {
                type: 'pHeading',
                value: {
                    heading: 'Day 4:',
                    body:
                        'The next day we had lunch at “Wahlburgers” my mom is a huge fan of their show and movies. She was so excited about being there. The burgers were yummy and it was a nice break from the heat sitting inside. We explored more of the malls that day and stopped by “Doughp” which is safe to eat raw cookie dough bar. Stephen said it was delicious and loved what he got. I got an icecream cone instead because I’m not a fan of raw cookie dough. Stephen and I had dinner on our own and had the best fried chicken ever. Omg it was mouth watering and flavorful. It was a fun restaurant to eat at. After dinner we met up with my parents and took them on “The High Roller” at the Linq. The linq took some really cute photos of us so we bought them. It’s such a fun experience going on that ferris wheel. You end up being higher up than most of the hotel/casinos and you get a view. It is truly beautiful to see all the lights at night.'
                }
            },
            {
                type: 'carousel',
                value: [
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_4_1.jpg',
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_4_2.jpg',
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_4_3.jpg',
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_4_4.jpg'
                ]
            },
            {
                type: 'pHeading',
                value: {
                    heading: 'Day 5:',
                    body:
                        'Thursday was our “Adventure Day”. We went to Red Rock Canyon and The Hoover Dam. Red Rock Canyon was beautiful. It is a one way drive with pull out spots to stop, park, and take photos. It felt significantly cooler over there further away from the strip. The canyons had so many sections of beautiful red, milky white, green, and more. It was colorful and honestly a really cool drive. The Hoover dam is unique and quite cool.It was just unbelievably HOT! I almost passed out because of how hot and sunny it was. The sun was blazing down on everyone there. We didn’t get to stay there as long but we did enjoy that we got to see it.'
                }
            },
            {
                type: 'carousel',
                value: [
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_5_1.jpg',
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_5_2.jpg',
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_5_3.jpg',
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_5_4.jpg'
                ]
            },
            {
                type: 'pHeading',
                value: {
                    heading: 'Day 6:',
                    body:
                        'Friday my parents flew back home (their second flight ever in their lives). We were sad to see them go but knew they had a blast and were happy to be home soon. Friday night was our big date night for the whole Baby Moon. Stephen being the planner that he had gotten us reservations for Eiffel Tower Restaurant months in advance. They texted us around Noon asking if we would like to up our reservation to 4:30pm so we could have a window table and see the sunset. We agreed and we were so glad that we did. Stephen told them that it was our Baby Moon and we were expecting our first child. We got congratulated by 5 staff members at the restaurant. The chef sent over a special cold zucchini soup as a gift. It was delicious. Different because it was cold but super flavorful. I ordered the pork chop with swiss chard and stephen ordered the filet of snapper with garlic panisse. Both meals were fabulous. We also had placed an order for two souffles at the beginning of our meal. We ordered a lemon blueberry souffle for me and a grand marnier souffle for Stephen. Those were to die for! I’ve never had something so tasty and fluffy before. Very tasty! We had a wonderful romantic dinner overlooking the strip. We got to enjoy the Bellagio fountain show multiple times. The sun started to set and we celebrated that new changes that are happening in our lives soon.'
                }
            },
            {
                type: 'carousel',
                value: [
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_6_1.jpg',
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_6_2.jpg',
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_6_3.jpg',
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_6_4.jpg',
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_6_5.jpg',
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_6_6.jpg',
                    'https://api.orteagone.com/Images/Blog/Babymoon_day_6_7.jpg'
                ]
            },
            {
                type: 'p',
                value:
                    'Although this is probably our last major vacation that is just “us two”, just Stephen and Sarah. That is perfectly fine for us. We waited so long to be blessed with a child we can’t wait to show Sophia the world our country! We already want to plan a Disney trip! We want to take her to Japan which one of our favorite countries in the world. We also want to take her to places we have yet to experience for ourselves; like South Korea, and places all over Europe.'
            },
            {
                type: 'p',
                value:
                    'This time we got to share together in Las Vegas was a blast. It was what we needed. I’m so thankful and blessed that I have the support system that I have. My husband you are my rock and my provider. Thank you for all you have done for me and my family over the past three weeks. Whether we needed help physically or even financially; you stepped up and genuinely helped out of the kindness of your heart. I knew I married a kind man before but seeing how you just wholeheartedly give and help in times of need just melts my heart.'
            },
            {
                type: 'carousel',
                value: ['https://api.orteagone.com/Images/Blog/Babymoon_leaving_1.jpg']
            },
            {
                type: 'p',
                value: 'I love you and thank you!'
            },
            {
                type: 'p',
                value: 'I look forward to many more adventures together with you and with our Sophia.'
            },
            {
                type: 'p',
                value: ' - Love, Sarah, Stephen, and Sophia.'
            }
        ]
    },
    {
        seo: 'Maternity-Photoshoot',
        title: 'Maternity Photoshoot',
        type: 'Personal',
        tags: ['Life', 'Family', 'Update', 'Baby'],
        dateAdded: '2020-02-22',
        dateUpdated: '2020-02-22',
        hero: 'https://api.orteagone.com/Images/Blog/Maternity_Photo_Shoot_1.jpg',
        description:
            'As our induction date approached I decided last minute I really wanted some "bump maternity photos". I was 37 weeks pregnant and I was told I could go into labor at any time.',
        body: [
            {
                type: 'p',
                value: 'I know this blog post is months later from when I gave birth but I still wanted to post the last few things about the end of my pregnancy journey.'
            },
            {
                type: 'p',
                value:
                    'As our induction date approached I decided last minute I really wanted some "bump maternity photos". I was 37 weeks pregnant and I was told I could go into labor at any time. That Thursday we had an ultrasound where they told us Sophia was really big and that my blood pressure was high. To be quite honest that stressed both Stephen and I out! We were very concerned about Sophia and my health. They said I could be induced immediately if my blood pressure continued to be high. The next day (Friday) I saw my OBGYN and she said my blood pressure was fine and for us to enjoy our date night. We went and saw "Miss Saigon" at the San Jose Performing Arts Center. We weren\'t sure if we should take the photos the next day or wait for the next weekend.'
            },
            {
                type: 'p',
                value:
                    "The next morning we woke up and both decided to do the photos since it was feeling like we might not make it to the next weekend. Stephen and I drove to this little park near the dam in Morgan Hill. It was so pretty and close to where we live. It was a beautiful November afternoon. Stephen was my photographer and I'm so proud of how these photos turned out."
            },
            {
                type: 'p',
                value:
                    "I really wanted these last minute moments captured on camera. It's not everyday you're pregnant. For us it was a long journey to conceive. It was a beautiful experience having my Sophia in my belly. I'm very blessed and thankful I got to experience pregnancy in this way."
            },
            {
                type: 'p',
                value:
                    "Random crazy fact; these photos were taken 3.5 days before I went into labor. Had we waited like we contemplated for the next weekend. We wouldn't have gotten to take bump photos. We would've had a newborn in the photos. I'm so glad I put it in our calendar and we got up and took the photos. I'll cherish these photos always."
            },
            {
                type: 'carousel',
                value: [
                    'https://api.orteagone.com/Images/Blog/Maternity_Photo_Shoot_1.jpg',
                    'https://api.orteagone.com/Images/Blog/Maternity_Photo_Shoot_2.jpg',
                    'https://api.orteagone.com/Images/Blog/Maternity_Photo_Shoot_3.jpg',
                    'https://api.orteagone.com/Images/Blog/Maternity_Photo_Shoot_4.jpg',
                    'https://api.orteagone.com/Images/Blog/Maternity_Photo_Shoot_5.jpg',
                    'https://api.orteagone.com/Images/Blog/Maternity_Photo_Shoot_6.jpg'
                ]
            },
            {
                type: 'p',
                value: ' - Sarah'
            }
        ]
    }
];

export default blogs;
