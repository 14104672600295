import React from 'react';

class Image extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isExpanded: false };

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    render() {
        var { disableExpand, src, webp, singleSrc, className, alt, style, imgStyle } = this.props;

        if ((singleSrc || '').length > 0) {
            src = singleSrc;
            webp = singleSrc.substring(0, singleSrc.length - 3) + 'webp';
        }

        var srcType = src.indexOf('jpg') > 0 ? 'image/jpeg' : 'image/png';

        return (
            <picture className={className || '' + (this.state.isExpanded && !disableExpand ? ' expanded' : '')} onClick={disableExpand ? null : this.onClick} style={style || {}}>
                <source srcSet={webp} type="image/webp" />
                <source srcSet={src} type={srcType} />
                <img src={src} alt={alt} style={imgStyle || {}} />
            </picture>
        );
    }
}
export default Image;
