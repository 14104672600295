import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer>
            <div className="row">
                <div className="col-xs-4">
                    <Link to="/Blog/Beauty/">Beauty</Link>
                    <Link to="/Blog/Crafts/">Crafts</Link>
                    <Link to="/Blog/Collections/">Collections</Link>
                    <Link to="/Blog/K-DASH/">K-DASH</Link>
                    <Link to="/Blog/Travel/">Travel</Link>
                </div>
                <div className="col-xs-4">
                    <Link to="/Blog/Personal/The-Weessies-Family-is-Upgrading-to-SGWx3/">Weessies Family news</Link>
                </div>
                <div className="col-xs-4">
                    <a href="https://www.instagram.com/orteagone/" rel="noopener noreferrer" target="_blank" title="My Instagram">
                        <i className="fab fa-instagram fa-2x" aria-hidden="true" />
                        <span> Orteagone</span>
                    </a>
                    <Link to="/Blog/Personal/">Personal</Link>
                    <Link to="/Sitemap/">Sitemap</Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
