import React from 'react';
import Image from '../Components/Image';

const DEFAULT_AUTO_PLAY_DURATION_IN_MILLISECONDS = 5000;

class Carousel extends React.Component {
    constructor(props) {
        super(props);

        this.state = { currentIndex: 0, intervalId: 0 };

        this.onNextClick = this.onNextClick.bind(this);
        this.onPreviousClick = this.onPreviousClick.bind(this);
        this.timer = this.timer.bind(this);
    }

    componentDidMount() {
        if (this.state.intervalId > 0) {
            return;
        }

        var intervalId = setInterval(this.timer, this.props.duration || DEFAULT_AUTO_PLAY_DURATION_IN_MILLISECONDS);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervalId: intervalId });
    }

    componentWillUnmount() {
        if (this.state.intervalId > 0) {
            return;
        }
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
        this.setState({ intervalId: 0 });
    }

    onNextClick() {
        if (this.state.intervalId > 0) {
            clearInterval(this.state.intervalId);
            this.setState({ intervalId: 0 });
        }

        var nextImage = this.state.currentIndex === this.props.images.length - 1 ? 0 : this.state.currentIndex + 1;
        this.setState({ currentIndex: nextImage });
    }

    onPreviousClick() {
        if (this.state.intervalId > 0) {
            clearInterval(this.state.intervalId);
            this.setState({ intervalId: 0 });
        }

        var prevImage = this.state.currentIndex === 0 ? this.props.images.length - 1 : this.state.currentIndex - 1;
        this.setState({ currentIndex: prevImage });
    }

    timer() {
        if (this.props.images.length <= 1) {
            return;
        }

        var nextImage = this.state.currentIndex === this.props.images.length - 1 ? 0 : this.state.currentIndex + 1;
        this.setState({ currentIndex: nextImage });
    }

    render() {
        var { images, style, className } = this.props;

        return (
            <div style={{ width: '100%', ...style }}>
                <Image
                    singleSrc={images[this.state.currentIndex]}
                    className={className}
                    style={{ width: '100%' }}
                    imgStyle={{ width: 'inherit', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
                {images.length > 1 ? (
                    <React.Fragment>
                        <button
                            onClick={this.onPreviousClick}
                            style={{
                                float: 'left',
                                position: 'relative',
                                top: '-200px',
                                background: '#8884',
                                border: 'none',
                                borderRadius: '1rem',
                                lineHeight: '2rem',
                                fontSize: '2rem',
                                cursor: 'pointer'
                            }}
                        >
                            &#60;
                        </button>
                        <button
                            onClick={this.onNextClick}
                            style={{
                                float: 'right',
                                position: 'relative',
                                top: '-200px',
                                background: '#8884',
                                border: 'none',
                                borderRadius: '1rem',
                                lineHeight: '2rem',
                                fontSize: '2rem',
                                cursor: 'pointer'
                            }}
                        >
                            &#62;
                        </button>
                    </React.Fragment>
                ) : null}
            </div>
        );
    }
}
export default Carousel;
