import React from "react";
import { Link } from "react-router-dom";
import Image from "../Components/Image";
import Tag from "../Components/Tag";

class BlogType extends React.Component {
  state = {
    blogs: []
  };

  componentDidMount() {
    import("../Data/blogs").then(blogs => {
      this.setState({
        blogs: blogs.default
      });
    });
  }

  render() {
    var blogType = this.props.match.params.blogType || "";
    var availableBlogs = this.state.blogs.filter(b => b && b.type === blogType);

    return (
      <main>
        <div className="wrapper">
          <h1>{blogType}</h1>
          <div className="blogs">
            {availableBlogs.length > 0 ? (
              availableBlogs.map(blog => (
                <article key={blog.seo} className="entry">
                  <Link to={"/Blog/" + blog.type + "/" + blog.seo + "/"}>
                    <Image singleSrc={blog.hero} disableExpand />
                    <legend>{blog.title}</legend>
                    <p>{blog.description}</p>
                  </Link>
                  <div className="info">
                    <div className="date">{blog.dateAdded}</div>
                    <aside className="tags">
                      {blog.tags.map(tag => (
                        <Tag key={blog.seo + tag} tagName={tag} />
                      ))}
                    </aside>
                  </div>
                </article>
              ))
            ) : (
              <p>Sorry no blog entries yet for this category.</p>
            )}
          </div>
        </div>
      </main>
    );
  }
}
export default BlogType;
